<template>
  <v-card :dark="$dark.get()">
    <Loader v-if="loading"></Loader>
    <v-card-title> Штрафы </v-card-title>
    <v-card-text>
      <v-simple-table fixed-header>
        <thead class="text-left">
          <tr>
            <th class="pr-4 pl-4">ID</th>
            <th class="pr-4 pl-4">Название</th>
            <th class="pr-4 pl-4">Сумма</th>
            <th class="pr-4 pl-4">Минут опоздания</th>
            <th class="pr-4 pl-4">Статичная сумма</th>
            <th class="pr-4 pl-4">Изменить</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in ALL_TYPES" :key="item.id">
            <td>{{ item.id }}</td>
            <td>{{ item.name }}</td>
            <td>{{ item.sum || 0 }} тг</td>
            <td>{{ item.minutesOfLate || 0 }} мин</td>
            <td>{{ item.isStaticSum ? "Да" : "Нет" }}</td>
            <td>
              <v-btn icon color="primary" @click="openRedactModal(item)">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-card-text>
    <v-dialog
      v-model="showModal"
      activator="parent"
      width="70%"
      @click:outside="showModal = false"
    >
      <v-card class="modal" :dark="$dark.get()">
        <v-card-title class="flex justify-center">
          Редактирование штрафа
        </v-card-title>
        <v-card-text v-if="redactType">
          <v-form ref="penForm">
            <v-text-field
              v-model="redactType.name"
              :rules="[$validate.required]"
              label="Название"
              required
              clearable
            ></v-text-field>
            <v-text-field
              v-model="redactType.sum"
              type="number"
              label="сумма"
              clearable
            ></v-text-field>
            <v-text-field
              v-model="redactType.minutesOfLate"
              type="number"
              label="Минут опоздания"
              clearable
            ></v-text-field>
            <v-checkbox
              v-model="redactType.isStaticSum"
              label="Статичная сумма"
            ></v-checkbox>
          </v-form>
        </v-card-text>
        <v-card-actions class="modal__actions">
          <v-btn @click="showModal = false">Закрыть</v-btn>
          <v-btn color="primary" @click="editPenalty()">Изменить</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import Loader from "@/components/Loader";
import ShowMessage from "@/Functions/message";
import api from "@/api";

export default {
  components: { Loader },
  data() {
    return {
      loading: true,
      redactType: null,
      showModal: false,
    };
  },
  computed: {
    ...mapGetters({
      ALL_TYPES: "Penalty/STATE",
    }),
  },
  async mounted() {
    await this.getPenalty();
    this.loading = false;
  },
  methods: {
    ...mapActions({
      getPenalty: "Penalty/GET_ALL_PENALTY",
    }),
    openRedactModal(type) {
      this.redactType = { ...type };
      this.showModal = true;
    },
    async editPenalty() {
      if (!this.$refs.penForm.validate()) {
        ShowMessage("Заполните необходимые поля");
        return;
      }
      const response = await api.Penalty.editPenalty(this.redactType);
      if (response.type === "error") {
        ShowMessage(response.data.message);
      } else {
        this.getPenalty();
        ShowMessage("Штраф изменен", true);
      }
      this.showModal = false;
    },
  },
};
</script>
<style lang="scss" scoped></style>
